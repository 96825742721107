import React from "react";
import { graphql } from "gatsby";

import { SEO } from "components/seo";
import { PageProps } from "types";

class NotFoundPage extends React.Component<PageProps> {
  render(): React.ReactNode {
    return (
      <div>
        <SEO title="404: Not Found" />
        <h1>Not Found</h1>
        <p>This page doesn&apos;t exist 🤷🏻‍♂️</p>
      </div>
    );
  }
}

export default NotFoundPage;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`;
